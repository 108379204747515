import Vue from 'vue';
import { 
    Button,
    Search,
    Swipe,
    SwipeItem,
    Grid,
    GridItem,
    Col,
    Row,
    Tabbar,
    TabbarItem,
    Card,
    Icon,
    Tag,
    List,
    Cell,
    Popup,
    DropdownMenu, 
    DropdownItem,
    Tab, Tabs ,Form,
    Field,
    CellGroup,
    Sidebar, 
    SidebarItem,
    Image as VanImage,
    Lazyload,
    Pagination,
    Stepper,
    GoodsAction, 
    GoodsActionIcon,
    GoodsActionButton,Sku,
    SwipeCell ,Checkbox, CheckboxGroup,
    Picker,
    Toast,
    SubmitBar,
    DatetimePicker,
    Loading,
    Calendar,
} from 'vant';

Vue.use(Button);
Vue.use(Search);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Col);
Vue.use(Row);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Card);
Vue.use(Icon);
Vue.use(Tag);
Vue.use(List);
Vue.use(Cell);
Vue.use(Popup);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Form);
Vue.use(Field);
Vue.use(CellGroup);
Vue.use(Sidebar);
Vue.use(SidebarItem);
Vue.use(VanImage);
Vue.use(Lazyload);
Vue.use(Pagination);
Vue.use(Stepper);
Vue.use(GoodsAction);
Vue.use(GoodsActionButton);
Vue.use(GoodsActionIcon);
Vue.use(Sku);
Vue.use(SwipeCell);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(SubmitBar);
Vue.use(Picker);
Vue.use(Toast);
Vue.use(Loading);
Vue.use(DatetimePicker);
Vue.use(Calendar);
