<template>
  <div>
    <div class="form">
      <div class="custom-select" @click="toggleOptions">
          <div class="custom-select-label">数据类型</div>
          <div class="custom-select-info" >
            <div>
              <div v-if="filter_info.typeVal">
                {{ filter_info.typeVal }}
              </div>
              <div v-else>
                选择数据类型
              </div>
            </div>
            <div>
              <van-icon v-if="selectDown" class="down" color="#AEB2BB" name="arrow-up" size="11"/>
              <van-icon v-else class="down" color="#AEB2BB" name="arrow-down" size="11"/>
            </div>
          </div>
      </div>
      <van-popup v-model="selectDown" position="bottom" >
                  <van-picker
                    :columns="dataType"
                    show-toolbar
                    title="选择数据类型"
                    @cancel="onCancel"
                    @change="onChange"
                    @confirm="onConfirm"
                    ref="mypopup"
                  />
      </van-popup>
      <div class="custom-select2" @click="toggleDates">
        <div class="custom-select-label">生效日期</div>
        <div class="custom-select-info">
          <div>
            <div v-if="filter_info.startVal&&filter_info.endVal">
              {{ filter_info.startVal }}<span class="padding-date">-</span>{{filter_info.endVal}}
            </div>
            <div v-else>
              选择生效日期
            </div>
          </div>
          <div>
            <van-icon v-if="dateDown" class="down" color="#AEB2BB" name="arrow-up" size="11"/>
            <van-icon v-else class="down" color="#AEB2BB" name="arrow-down" size="11"/>
          </div>
        </div>
      </div>
      <van-calendar
        v-model="dateDown"
        type="range"
        :show-confirm="false"
        @close="dateDown=false"
        :min-date="minDate"
        :max-date="maxDate"
        color="#419EFF"
        safe-area-inset-bottom
        ref="myCalendar"
        @confirm="onConfirmCalendar"
      >
      </van-calendar>
      <div class="footer-buttom">
        <van-button icon="search" type="info" :loading="searchLoad" @click="onsearch">搜索</van-button>
        <van-button  plain type="info" @click="clearAll">
            <img width="14" src="../../static/clean.svg"/>
            清除条件
        </van-button>
      </div>
    </div>
    <div v-if="loading" class="load">
      <van-loading size="24px">加载中...</van-loading>
    </div>
    <div v-else>
      <div v-if="exchangeRate.length>0" style="height: calc(100% - 256px);width: 100%">
        <div class="history-info">
          <van-list
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
          >
          <div class="history-item">
            <div  class="history-content" v-for="item in exchangeRate">
              <div class="ranking-header">
                {{dataType[item.sjlx]}}
              </div>
              <!--     有数据 本周签约使用汇率      -->
              <div class="ranking-text">
                <div class="time">
                  <div class="title">
                    生效日期
                  </div>
                  <div class="info">{{dateFormats(item.sxrq)}}0点至{{dateFormats(item.dqrq)}}24点</div>
                </div>
                <div class="rate">
                  <div class="rate-info">
                    <div class="rate-title">澳币汇率</div>
                    <div style="margin-top: 10px">{{ item.ab?item.ab:'-' }}</div>
                  </div>
                  <div class="rate-info">
                    <div class="rate-title">美元汇率</div>
                    <div style="margin-top: 10px">{{ item.my?item.my:'-' }}</div>
                  </div>
                  <div class="rate-info">
                    <div class="rate-title">加币汇率</div>
                    <div style="margin-top: 10px">{{ item.jb?item.jb:'-' }}</div>
                  </div>
                  <div class="rate-info">
                    <div class="rate-title">新币汇率</div>
                    <div style="margin-top: 10px">{{ item.xb?item.xb:'-' }}</div>
                  </div>
                  <div class="rate-info">
                    <div class="rate-title">港币汇率</div>
                    <div style="margin-top: 10px">{{ item.gb?item.gb:'-' }}</div>
                  </div>
                </div>
                <div class="ramark">
                  <div class="title">
                    备注
                  </div>
                  <div class="info">{{ item.bz?item.bz:'-' }}</div>
                </div>
              </div>
            </div>
          </div>
          </van-list>
        </div>
      </div>
      <div v-else class="history-empty">
        <img alt="" src="../../static/empty.png">
        <div class="empty-text">{{emtry}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import {Toast} from "vant";
import {GetDataType} from "@/api";
import moment from "moment";

export default {
  //import引入的组件需要注入到对象中才能使用
  props: ['loading', 'filter_info', 'changeDate', 'changeType', 'fetchDataNew', 'exchangeRate', 'loadingHistoryNew', 'tabStatus','currentPage','pageTotal','onsearch','clearAll','finished','emtry','searchLoad'],
  components: {},
  data() {
    //这里存放数据
    return {
      searchIcon: 'search',
      loadingNew: false,
      page: 1, // 当前页数
      selectDown: false,
      dateDown: false,
      typeVal: '',
      minDate: new Date(2000,0,1), // 最小日期
      maxDate: new Date(2100,0,31), // 最大日期
      dateValTime: '',
      dateFormat: 'yyyy-MM-dd', // 日期格式
      dataType: [],//数据类型下拉数据
      showOverlay:false,
    };
  },
  //方法集合
  methods: {
    toggleOptions(e) {
      this.selectDown = !this.selectDown
    },
    toggleDates(e) {
      this.dateDown = true
    },
    onCancel(){
      this.selectDown=false
    },
    onConfirm(value, index) {
      let selectedKey = '';
      for (let key in this.dataType) {
        if (this.dataType.hasOwnProperty(key)) {
          let val = this.dataType[key];
          if (value === val) {
            selectedKey = key;
            break;
          }
        }
      }
      this.changeType({value: value, key: selectedKey})
      this.selectDown=false
    },
    onChange(picker, value, index) {
    },
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD')
      // return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    },
    onConfirmCalendar(value){
      const [start, end] = value;
      this.changeDate({
        start: this.formatDate(start),
        end: this.formatDate(end)
      })
      this.dateDown = false;
    },
    // 获取数据
    handleScroll() {
      // 监听滚动事件，当滚动到页面底部时加载更多数据
    },
    // 获取数据类型列表
    getDataType() {
      GetDataType().then(data => {
        if (data.code === 200) {
          this.dataType = Object.values(data.data);
        } else {
          if (!isInterceptorCode(data.code)) {
            Toast(data.msg)
          }
        }
      }).catch(() => {
        Toast('服务器连接失败!')
      })
    },
    dateFormats(date) {
      return moment(date).format('YYYY年MM月DD日')
    },
    onLoad(){
      this.fetchDataNew()
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getDataType()
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
  },
}
</script>
<style scoped>
.form {
    border-bottom: 1px solid #ECEEF5;
    align-items: center;
    padding: 40px 36px ;
    background: #FFFFFF;
    margin: 30px 0px;
    border-radius: 20px;
}

.custom-select {
    width: 100%;
}

.custom-select2 {
    cursor: pointer;
    min-width: 158px;
    margin-top: 40px;

}

.selected-option {
    min-width: 120px;
    color: #419EFF;
}

.selected-option-placeholder {
    min-width: 120px;
}

.down {
    margin-left: 14px;
}

.history-info {
    margin-top: 40px;
    height: 100%;
    width: 100%;
    /*overflow-y: auto;*/
}

.history-content {
    width: 618px;
    min-height: 535px;
    background: #FFFFFF;
    border-radius: 20px;
    border: 1px solid #EAEAEA;
    margin-bottom: 30px;
}

.history-content:last-child {
    margin-bottom: 0;
}

.history-content:nth-child(even) {
    margin-right: 0; /* 最后一个盒子不设置右侧间距 */
}

.rate {
    display: flex;
    font-size: 24px;
    flex-wrap: wrap;
}

.rate-info {
    margin-right: 61px;
    margin-bottom: 30px;
}

.rate-title {
    font-weight: 600;
    color: #606266;
    line-height: 40px;
}

.ramark .title {
    height: 40px;
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 24px;
    color: #606266;
    line-height: 40px;
}

.ramark .info {
    font-weight: 400;
    font-size: 24px;
    color: #606266;
    line-height: 45px;
    word-break: break-word;
}

.ranking-header {
    width: 100%;
    height: 100px;
    background: #F5F5FC;
    border-radius: 20px 20px 0px 0px;
    border-bottom: 1px solid #EAEAEA;
    font-weight: 600;
    font-size: 28px;
    color: #606266;
    line-height: 40px;
    padding: 31px  0 31px  40px ;
    box-sizing: border-box;
}

.ranking-text {
    min-height: calc(100% - 100px);
    padding: 40px;
    box-sizing: border-box;
}

.history-empty {
    background: #FFFFFF;
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 24px;
    color: #606266;
    height: calc(100vh - 769px);
    box-sizing: border-box;
    border-radius:20px ;
}

.history-empty img {
    width: 400px;
}

.load {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 28.95vh);
    width: 100%
}

.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.footerBtn {
    width: 250px;
    border-radius: 2px;
}
.history-item{
    background: #fff;
    padding: 40px 36px;
    border-radius: 20px;
}

.custom-select-label{
    height: 42px;
    font-weight: 600;
    font-size: 30px;
    color: #48505F;
    line-height: 42px;
    margin-bottom: 30px;
}
.custom-select-info{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 76px;
    padding: 17px 20px;
    background: #F5F5FD;
    border-radius: 16px;
    box-sizing: border-box;
    color: #606266;
    font-size: 30px;
}
.footer-buttom{
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
}
.footer-buttom >>> .van-button--normal{
    padding: 0 58px;
    width: 294px;
    border-radius: 16px;
}
.footer-buttom >>>.van-button__icon+.van-button__text, .van-button__loading+.van-button__text, .van-button__text+.van-button__icon, .van-button__text+.van-button__loading{
    margin-left: 30px;
}
.footer-buttom >>>.van-button--plain.van-button--info img{
    margin-right: 15px;
}
.padding-date{
    margin: 0 20px;
}
.time{
    margin-bottom: 25px;
}
.time .title{
    height: 40px;
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 28px;
    color: #606266;
    line-height: 40px;
}
.time .info{
    /*height: 40px;*/
    font-weight: 400;
    font-size: 28px;
    color: #606266;
    line-height: 40px;
    text-align: left;
}
</style>