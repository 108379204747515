export function isInterceptorCode(code) {
  switch (code) {
    case 100: //缺失令牌
    case 101: // 令牌错误
    case 102: //令牌过期
    case 105: //无效令牌
    case 403: //非法账户
      return true;
    default:
      break;
  }
  return false;
}

