<template>
  <div class='main-body'>
      <div class="ranking-content">
          <div class="ranking-header">
              本周签约使用汇率
          </div>
          <!--     有数据 本周签约使用汇率      -->
          <div v-if="signed_week">
              <div class="ranking-text">
                  <div class="time">
                      <div class="title">
                          生效日期
                      </div>
                      <div class="info">{{dateFormat(signed_week.sxrq)}}0点至{{dateFormat(signed_week.dqrq)}}24点</div>
                  </div>
                  <div class="rate">
                      <div class="rate-info">
                          <div class="rate-title">澳币汇率</div>
                          <div style="margin-top: 10px">{{signed_week.ab?signed_week.ab:'-'}} </div>
                      </div>
                      <div class="rate-info">
                          <div class="rate-title">美元汇率</div>
                          <div style="margin-top: 10px">{{signed_week.my?signed_week.my:'-'}} </div>
                      </div>
                      <div class="rate-info">
                          <div class="rate-title">加币汇率</div>
                          <div style="margin-top: 10px">{{signed_week.jb?signed_week.jb:'-'}} </div>
                      </div>
                      <div class="rate-info">
                          <div class="rate-title">新币汇率</div>
                          <div style="margin-top: 10px">{{signed_week.xb?signed_week.xb:'-'}} </div>
                      </div>
                      <div class="rate-info">
                          <div class="rate-title">港币汇率</div>
                          <div style="margin-top: 10px">{{signed_week.gb?signed_week.gb:'-'}} </div>
                      </div>
                  </div>
                  <div class="ramark">
                      <div class="title">
                          备注
                      </div>
                      <div class="info">{{signed_week.bz?signed_week.bz:'-'}}</div>
                  </div>
              </div>
          </div>
          <!--      无数据 本周签约使用汇率     -->
          <div v-else class="table-empty">
              <img src="../../static/empty.png" alt="" width="200">
              <div class="empty-text">此时间段内汇率数据未设置，请联系集团财务获取！</div>
          </div>
      </div>
      <div class="ranking-content">
          <div class="ranking-header">
              本周核算业绩使用汇率
          </div>
          <!--     有数据 本周签约使用汇率      -->
          <div v-if="week">
              <div class="ranking-text">
                  <div class="time">
                      <div class="title">
                          生效日期
                      </div>
                      <div class="info">{{dateFormat(week.sxrq)}}0点至{{dateFormat(week.dqrq)}}24点</div>
                  </div>
                  <div class="rate">
                      <div class="rate-info">
                          <div class="rate-title">澳币汇率</div>
                          <div style="margin-top: 10px">{{week.ab?week.ab:'-'}} </div>
                      </div>
                      <div class="rate-info">
                          <div class="rate-title">美元汇率</div>
                          <div style="margin-top: 10px">{{week.my?week.my:'-'}} </div>
                      </div>
                      <div class="rate-info">
                          <div class="rate-title">加币汇率</div>
                          <div style="margin-top: 10px">{{week.jb?week.jb:'-'}} </div>
                      </div>
                      <div class="rate-info">
                          <div class="rate-title">新币汇率</div>
                          <div style="margin-top: 10px">{{week.xb?week.xb:'-'}} </div>
                      </div>
                      <div class="rate-info">
                          <div class="rate-title">港币汇率</div>
                          <div style="margin-top: 10px">{{week.gb?week.gb:'-'}} </div>
                      </div>
                  </div>
                  <div class="ramark">
                      <div class="title">
                          备注
                      </div>
                      <div class="info">{{week.bz?week.bz:'-'}}</div>
                  </div>
              </div>
          </div>
          <!--      无数据 本周签约使用汇率     -->
          <div v-else class="table-empty">
              <img src="../../static/empty.png" alt="" width="400">
              <div class="empty-text">此时间段内汇率数据未设置，请联系集团财务获取！</div>
          </div>
      </div>
      <div class="ranking-content">
          <div class="ranking-header">
              本季度核算提成使用汇率
          </div>
          <!--     有数据 本周签约使用汇率      -->
          <div v-if="quarter">
              <div class="ranking-text">
                  <div class="time">
                      <div class="title">
                          生效日期
                      </div>
                      <div class="info">{{dateFormat(quarter.sxrq)}}0点至{{dateFormat(quarter.dqrq)}}24点</div>
                  </div>
                  <div class="rate">
                      <div class="rate-info">
                          <div class="rate-title">澳币汇率</div>
                          <div style="margin-top: 10px">{{quarter.ab?quarter.ab:'-'}} </div>
                      </div>
                      <div class="rate-info">
                          <div class="rate-title">美元汇率</div>
                          <div style="margin-top: 10px">{{quarter.my?quarter.my:'-'}} </div>
                      </div>
                      <div class="rate-info">
                          <div class="rate-title">加币汇率</div>
                          <div style="margin-top: 10px">{{quarter.jb?quarter.jb:'-'}} </div>
                      </div>
                      <div class="rate-info">
                          <div class="rate-title">新币汇率</div>
                          <div style="margin-top: 10px">{{quarter.xb?quarter.xb:'-'}} </div>
                      </div>
                      <div class="rate-info">
                          <div class="rate-title">港币汇率</div>
                          <div style="margin-top: 10px">{{quarter.gb?quarter.gb:'-'}} </div>
                      </div>
                  </div>
                  <div class="ramark">
                      <div class="title">
                          备注
                      </div>
                      <div class="info">{{quarter.bz?quarter.bz:'-'}}</div>
                  </div>
              </div>
          </div>
          <!--      无数据 本周签约使用汇率     -->
          <div v-else class="table-empty">
              <img src="../../static/empty.png" alt="" width="200">
              <div class="empty-text">此时间段内汇率数据未设置，请联系集团财务获取！</div>
          </div>
      </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props:['signed_week','week','quarter'],
  components: {},
    data() {
    //这里存放数据
      return {
      };
    },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    dateFormat(date){
      return moment(date).format('YYYY年MM月DD日')
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {

  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped>
.ranking-content{
    width: 618px;
    min-height: 665px;
    background: #FFFFFF;
    border-radius: 20px;
    border: 1px solid #EAEAEA;
    margin-bottom: 30px;
}
.ranking-content:last-child{
    margin-bottom: 0;
}
.ranking-header{
    width:100%;
    height: 100px;
    background: #F5F5FC;
    border-radius: 20px 20px 0px 0px;
    border-bottom: 1px solid #EAEAEA;
    font-weight: 600;
    font-size: 28px;
    color: #606266;
    line-height: 40px;
    padding: 31px 40px;
    box-sizing: border-box;
}

.table-empty{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 109px;
}
.table-empty img{
    width: 400px;
}
.empty-text{
    font-weight: 400;
    font-size: 28px;
    color: #606266;
    line-height: 40px;
    text-align: center;
    width: 374px;
}
/* ---- S 表单筛选项 ---- */


.ranking-text{
    height: calc(100% - 100px);
    padding: 40px;
    box-sizing: border-box;
}


.rate{
    display: flex;
    font-size: 28px;
    flex-wrap: wrap;
}
.rate-info{
    margin-right: 61px;
    margin-bottom: 30px;
}
.rate-title{
    font-weight: 600;
    color: #606266;
    line-height: 40px;
}
.ramark .title{
    height: 40px;
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 28px;
    color: #606266;
    line-height: 40px;
}
.ramark .info{
    font-weight: 400;
    font-size: 28px;
    color: #606266;
    line-height: 45px;
    word-break: break-word;
}
.time{
    margin-bottom: 25px;
}
.time .title{
    height: 40px;
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 28px;
    color: #606266;
    line-height: 40px;
}
.time .info{
    /*height: 40px;*/
    font-weight: 400;
    font-size: 28px;
    color: #606266;
    line-height: 40px;
    text-align: left;
}
</style>